
import { Events } from "@/api";
import { VideoListItem } from "@/api/types";
import { Component, Prop, Vue } from "vue-property-decorator";
import { downloadFile, formateDate } from "../../utils/helpers";

@Component
export default class VideoListModal extends Vue {
  @Prop({ default: () => [] })
  videos!: VideoListItem[];

  loading = false;
  downloading: string | null = null;
  videoPreview = "";
  downloadFile = downloadFile;
  formateDate = formateDate;

  headers = [
    {
      text: "File",
      align: "start",
      sortable: false,
      value: "originalName"
    },
    {
      text: "Date",
      align: "center",
      sortable: false,
      value: "createdAt",
      width: 150
    },
    {
      text: "",
      align: "center",
      sortable: false,
      value: "actions",
      width: 100
    }
  ];

  onCloseModal() {
    this.close();
  }

  close() {
    this.$modal.hide("video-files-modal");
  }

  async downloadRecord(video: VideoListItem) {
    this.downloading = video.id;
    try {
      const file = await Events.downloadRecordById(video.id);
      if (file) {
        this.downloadFile(file, video.originalName, false);
        this.$success("Record has been downloaded");
      }
    } catch (e) {
      const err = e as any;

      const errText = JSON.parse(await err.response.data.text());
      this.$error(
        (errText?.result?.message && { err: errText.result.message }) || {
          err: "File not found"
        }
      );
    } finally {
      this.downloading = null;
    }
  }
}
