
import { Events } from "@/api";
import { EventStat } from "@/api/types";
import Card from "@/components/common/Card.vue";
import PageTitle from "@/components/common/PageTitle.vue";
import Table from "@/components/common/Table.vue";
import TitleHeader from "@/components/common/TitleHeader.vue";
import DelegateOnlineCoursesGradeList from "@/components/events/DelegateOnlineCoursesGradeList.vue";
import DelegatesGradeList from "@/components/events/DelegatesGradeList.vue";
import ScheduleQualification from "@/components/events/ScheduleQualification.vue";
import ZoomSchedule from "@/components/zoom/ZoomScheduleModule.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Component, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {
    DelegatesGradeList,
    DelegateOnlineCoursesGradeList,
    Table,
    TitleHeader,
    PageTitle,
    Card,
    ValidationObserver,
    ValidationProvider,
    ZoomSchedule,
    ScheduleQualification
  }
})
export default class EventsViewPage extends Vue {
  OCCUPATIONAL_QUALIFICATION_ID = 8;

  LiftingData: {
    code: string;
    capacity: string;
    update: boolean;
  } = {
    code: "",
    capacity: "",
    update: true
  };
  isCreateLifting = true;
  event: any = {};
  stat: EventStat = {
    capacity: 0,
    capacityPercent: 0,
    attendance: 0,
    graduationPassed: 0,
    graduationNotPassed: 0,
    companies: 0,
    certified: 0,
    income: 0
  };
  actions = [
    {
      title: "View",
      action: "view"
    },
    {
      title: "Edit",
      action: "edit"
    },
    {
      title: "Delete",
      action: "delete"
    }
  ];

  headersTitle = [{ icon: "mdi-calendar-month", header: "Course-Event name" }];
  courseOverview: Array<{ title?: string; content?: string }> = [];

  get isOccupationalQualification() {
    return this.event?.category?.id == this.OCCUPATIONAL_QUALIFICATION_ID;
  }
  get stats() {
    return [
      { title: "Companies", content: `${this.stat.companies ?? "no data"}` },
      {
        title: "Attendance",
        content: `${this.stat.attendance ?? "no data"}`
      },
      { title: "Certified", content: `${this.stat.certified ?? "no data"}` },
      {
        title: "Graduation",
        content: `${this.stat.graduationPassed ?? "no data"}% / ${this.stat
          .graduationNotPassed ?? "no data"}%`
      },
      {
        title: "Income",
        content: `${new Intl.NumberFormat("en", {
          style: "currency",
          currency: "ZAR",
          minimumFractionDigits: 2
        })
          .format(this.stat.income)
          .substr(2) ?? "no data"}`
      }
    ];
  }
  get eventId() {
    return this.$route.params.id;
  }
  get eventTypeName() {
    switch (this.event.eventTypeId) {
      case 1:
        return "Course";
      case 2:
        return "Audit";
      case 3:
        return "Online";

      default:
        return null;
    }
  }
  get overviewCardTitle() {
    switch (this.event.eventTypeId) {
      case 1:
        return "Course overview";
      case 2:
        return "Audit overview";
      case 3:
        return "Online course overview";
      default:
        return null;
    }
  }
  get buttonsArray() {
    return [
      {
        text: "Back",
        icon: "mdi-chevron-left",
        color: "white",
        action: "back"
      },
      {
        text: "Delete",
        icon: "mdi-delete",
        color: "gray",
        action: "delete",
        disabled: this.event.blockDeletion
      },
      {
        text: "Edit",
        icon: "mdi-pencil-box-outline",
        color: "dark",
        action: "edit",
        disabled: this.event.blockDeletion
      }
    ];
  }
  get offlineTrainings() {
    return this.event?.offlineTrainings || [];
  }

  async created() {
    try {
      [this.event, this.stat] = await Promise.all([
        Events.infoAny(this.eventId),
        Events.stats(this.eventId)
      ]);
    } catch (e) {
      const err = e as any;
      await this.$error(err.errors);
    }
  }

  @Watch("event")
  async updEvent() {
    if (this.event.eventTypeId === 1) {
      if (this.event.course && this.event.course.liftingMachine) {
        this.LiftingData = await Events.getLiftingMachine(this.eventId).catch(
          () => {
            //
          }
        );
      }

      this.isCreateLifting = !this.LiftingData.code;
      this.headersTitle[0].header = this.event.name;
      this.courseOverview = [
        { title: "Name", content: this.event.course.name },
        { title: "Certificate", content: this.event.course.courseType },
        {
          title: "Cost Per Day",
          content: `${new Intl.NumberFormat("en", {
            style: "currency",
            currency: "ZAR",
            minimumFractionDigits: 2
          })
            .format(this.event.course.price)
            .substr(2)}`
        },
        {
          title: this.event.course.liftingMachine ? "DOL #" : "",
          content: this.event.course.liftingMachine ? this.event.course.dol : ""
        },
        {}
      ];
    }
    if (this.event.eventTypeId === 2) {
      this.courseOverview = [
        { title: "Name", content: this.event.audit.name },
        // { title: "Certificates", content: this.event.course.courseType },
        {
          title: "Cost Per Day",
          content: `${new Intl.NumberFormat("en", {
            style: "currency",
            currency: "ZAR",
            minimumFractionDigits: 2
          })
            .format(this.event.audit.price)
            .substr(2)}`
        }
        // {
        //   title: this.event.course.liftingMachine ? "DOL #" : "",
        //   content: this.event.course.liftingMachine
        //     ? this.event.course.dol
        //     : ""
        // },
      ];
    }
    if (this.event.eventTypeId === 3) {
      if (this.event.onlineCourse && this.event.onlineCourse.liftingMachine) {
        this.LiftingData = await Events.getLiftingMachine(this.eventId).catch(
          () => {
            //
          }
        );
      }

      this.isCreateLifting = !this.LiftingData.code;
      this.headersTitle[0].header = this.event.name;
      this.courseOverview = [
        { title: "Name", content: this.event.onlineCourse.name },
        // {
        //   title: "Certificate",
        //   content: this.event.courseonlineCourse.courseType
        // },
        {
          title: "Cost Per Day",
          content: `${new Intl.NumberFormat("en", {
            style: "currency",
            currency: "ZAR",
            minimumFractionDigits: 2
          })
            .format(this.event.onlineCourse.price)
            .substr(2)}`
        },
        {
          title: this.event.onlineCourse.liftingMachine ? "DOL #" : "",
          content: this.event.onlineCourse.liftingMachine
            ? this.event.course.dol
            : ""
        },
        {}
      ];
    }
  }

  async onDelete() {
    const res = await this.$dialog.error({
      text: "Do you really want to delete item?",
      title: "Warning",
      persistent: false,
      actions: {
        No: { text: "No" },
        Yes: {
          color: "red",
          text: "Yes I do"
        }
      }
    });
    if (res === "Yes") {
      try {
        await Events.remove(this.event.id);
        await this.onBack();
      } catch (e) {
        const err = e as any;
        await this.$error(err.errors);
      }
    }
  }
  async onEdit() {
    await this.$router.push({
      name: "EditEvent",
      params: { id: this.eventId }
    });
  }
  async onBack() {
    await this.$router.push({ name: "CourseEvents" });
  }
  async saveLiftingMachine() {
    try {
      if (this.isCreateLifting) {
        await Events.createLiftingMachine(this.eventId, this.LiftingData);
      } else {
        await Events.updateLiftingMachine(this.eventId, this.LiftingData);
      }
      await this.$success("<strong>Success!</strong> Data has been saved!");
      this.LiftingData = await Events.getLiftingMachine(this.eventId);
    } catch (e) {
      const err = e as any;
      await this.$error(err.errors);
    }
  }
}
