
import { Zoom } from "@/api";
import { Delegate } from "@/api/types";
import moment from "moment";
import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import ScheduleModal from "./ScheduleModal.vue";

export type ScheduleCall = {
  sessionName: string;
  startDate: string;
  endDate: string;
  zoomLink: string;
  zoomMeetingId: number;
  delegates: { id: string; name: string; surname: string }[];
  id: number;
};

const Delegates = namespace("delegate");

@Component({
  components: {
    ScheduleModal
  }
})
export default class ZoomSchedule extends Vue {
  @Delegates.State("eventDelegates")
  eventDelegates!: Delegate[];
  @Prop({ required: true })
  eventId!: string;

  calls: ScheduleCall[] = [];
  loading = false;

  get availableDelegates() {
    return this.eventDelegates.filter(
      delegate =>
        !this.calls.some(call => call.delegates.find(d => d.id === delegate.id))
    );
  }

  get canCreateCall() {
    return this.availableDelegates.length > 0;
  }

  async created() {
    await this.getShedule();
  }

  async getShedule() {
    this.loading = true;
    try {
      const res = await Zoom.getScheduleCalls(this.eventId);
      this.calls = res;
    } catch (e) {
      const err = e as any;
      await this.$error(err.errors);
    } finally {
      this.loading = false;
    }
  }

  async deleteCall(callId: number) {
    const res = await this.$dialog.error({
      text: "Delete call?",
      title: "Warning",
      persistent: false,
      actions: {
        No: { text: "No" },
        Yes: {
          color: "red",
          text: "Yes I do"
        }
      }
    });
    if (res === "Yes") {
      this.loading = true;
      try {
        await Zoom.deleteCall(callId);

        this.calls = this.calls.filter(call => call.id !== callId);
      } catch (e) {
        const err = e as any;
        await this.$error(err.errors);
      } finally {
        this.loading = false;
      }
    }
  }

  delegateString(call: ScheduleCall) {
    const delegates = call.delegates;
    return delegates
      .map(delegate => `${delegate.name} ${delegate.surname}`)
      .join(", ");
  }

  showModal() {
    this.$modal.show("zoom-schedule-modal");
  }
  formatedDate(date: string) {
    return moment(date).format("DD-MM-YYYY HH:mm");
  }
}
