
import { Zoom } from "@/api";
import moment from "moment";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Component, Prop, Vue } from "vue-property-decorator";

type Session = {
  name: string;
  date: string;
  time: string;
  duration: number;
};

@Component({
  components: { ValidationObserver, ValidationProvider }
})
export default class ScheduleModal extends Vue {
  @Prop()
  eventId!: string;
  @Prop()
  availableDelegates!: any[];

  meetingDurations = [
    { id: 1, name: "15 minutes", val: 15 },
    { id: 1, name: "30 minutes", val: 30 },
    { id: 1, name: "45 minutes", val: 45 },
    { id: 1, name: "60 minutes", val: 60 }
  ];
  initialDelegate = { id: 0, name: "", surname: "" };

  initialSession: Session = { name: "", date: "", time: "", duration: 30 };
  session: Session = { ...this.initialSession };
  menu1 = false;
  menu2 = false;
  loading = false;

  selectedDelegates = [JSON.parse(JSON.stringify(this.initialDelegate))];

  close() {
    this.$modal.hide("zoom-schedule-modal");
  }

  async send() {
    const delegates: any[] = this.selectedDelegates
      .filter(i => i.id)
      .map(i => i.id);
    const timeSplited = this.session.time.split(":");
    const hour = +timeSplited[0];
    const minutes = +timeSplited[1];
    const startDateT = moment(this.session.date)
      .set("hour", hour)
      .set("minute", minutes);
    const endDate = moment(startDateT)
      .add(this.session.duration, "minute")
      .toISOString();
    const startDate = startDateT.toISOString();

    const data = {
      sessionName: this.session.name,
      eventId: this.eventId,
      endDate,
      startDate,
      delegates: delegates
    };

    try {
      this.loading = true;
      await Zoom.createCall(data);
      this.selectedDelegates = [
        JSON.parse(JSON.stringify(this.initialDelegate))
      ];
      this.checkDelegates();
      this.$emit("created");

      this.session = { ...this.initialSession };
      this.session.duration = 30;
      this.selectedDelegates = [
        JSON.parse(JSON.stringify(this.initialDelegate))
      ];
      this.close();
      this.$success("Call has been created");
    } catch (e) {
      const err = e as any;
      await this.$error(err.errors);
    } finally {
      this.loading = false;
    }
  }

  addDelegate() {
    if (this.selectedDelegates.length < 4) {
      this.selectedDelegates.push(
        JSON.parse(JSON.stringify(this.initialDelegate))
      );
    }
  }

  // @Watch("selectedDelegates", { deep: true })
  checkDelegates(): void {
    this.availableDelegates.forEach((c: any, index: number) => {
      if (this.selectedDelegates.some(com => com.id === c.id)) {
        this.availableDelegates[index].disabled = true;
      } else {
        this.availableDelegates[index].disabled = false;
      }
    });
  }
}
