
import { Companies, Events } from "@/api";
import { Delegate, DelegatesWithPagination } from "@/api/types";
import TitleButtons from "@/components/common/TitleButtons.vue";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import PageTitle from "../common/PageTitle.vue";
import TrainingRegisterDownloadModal from "./TrainingRegisterDownloadModal.vue";
@Component({
  components: { TitleButtons, PageTitle, TrainingRegisterDownloadModal }
})
export default class DelegatesGradeList extends Vue {
  @Prop({ required: true })
  eventId!: string;
  @Prop({ required: true, default: () => [] })
  offlineTrainings!: any[];

  headersTitle = [
    { icon: "mdi-calendar-month", header: "Attendance and Graduation" }
  ];
  buttonsArray = [
    {
      text: "Retake",
      icon: "mdi-pencil-box-outline",
      color: "white",
      action: "clear",
      disabled: false,
      activeClass: ""
    },
    {
      text: "Sign",
      icon: "mdi-checkbox-marked-circle-outline",
      color: "dark",
      action: "save"
    }
  ];
  delegates: Array<Delegate> = [];
  gradesList: { [k: string]: number } = {};
  delegateName = "";
  companies = [];
  filters = {
    company: null,
    name: ""
  };
  delegatesWithPagination: DelegatesWithPagination = {
    meta: {
      perPage: 10
    }
  };
  options = {};
  page = 1;
  headersData = [
    {
      text: "Name",
      value: "name"
    },
    {
      text: "Company",
      value: "company.name"
    },
    {
      text: "Passport / ID",
      value: "passport"
    },
    {
      text: "Cell phone",
      value: "cellPhone"
    },
    { text: "Grade", value: "gradeStatus", align: "center" },
    { text: "Attend", value: "attendStatus", align: "center" }
  ];

  initialSelectedDelegate = {
    id: 0,
    passport: "",
    phone: "",
    name: ""
  };
  selectedDelegate: {
    id?: number | string;
    passport?: string;
    phone?: string;
    name?: string;
  } = { ...this.initialSelectedDelegate };

  get headers() {
    const headers = [...this.headersData];

    if (this.showTrainingRegister)
      headers.push({ text: "Actions", value: "actions", align: "center" });
    return headers;
  }
  get showTrainingRegister() {
    return this.offlineTrainings?.length;
  }
  get canDownloadTrainingRegister() {
    return !!this.filters.company;
  }
  get canSetGrade() {
    const availableDelegates = this.delegates.filter(
      (delegate: Delegate) => delegate.isDisabled === false
    );
    let res = false;
    if (!availableDelegates.length) {
      return (res = true);
    }
    availableDelegates.forEach((delegate: Delegate) => {
      if (!delegate.currentEvent.pivot.grade) {
        return (res = true);
      }
    });
    return res;
  }

  async onDownloadTrainingRegister() {
    try {
      if (this.filters.company) {
        const fileName = `${this.eventId}.pdf`;
        const data = await Events.downloadTrainingRegister(
          this.eventId,
          this.filters.company
        );
        const downloadUrl = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");

        link.href = downloadUrl;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
    } catch (e) {
      const err = e as any;
      await this.$error(err.errors);
    }
  }
  async created() {
    this.companies = await Companies.listWithoutPagination();
    await this.loadDelegatesData(this.filters);
  }

  @Watch("filters", { deep: true })
  async onChangeFilters() {
    await this.loadDelegatesData(this.filters);
  }
  onClearSignature() {
    (this.$refs.signaturePad as Vue & {
      clearSignature: () => void;
    }).clearSignature();
  }
  async onSaveSignature() {
    const errors = [];
    const { isEmpty, data } = (this.$refs.signaturePad as Vue & {
      saveSignature: () => any;
    }).saveSignature();
    if (isEmpty) {
      errors.push("The signature should not be empty!");
    }
    if (!this.selectedDelegate.passport) {
      errors.push("The Passport/ID should not be empty!");
    }
    if (errors.length > 0) {
      const message = errors.reduce((res: string, error: string) => {
        return res + `${error}<br>`;
      }, "");
      await this.$dialog.error({
        text: message,
        title: "Error",
        persistent: false,
        actions: {
          Yes: {
            color: "red",
            text: "OK"
          }
        }
      });
    } else {
      try {
        await Events.setAttend(this.eventId, {
          delegateId: this.selectedDelegate.id,
          passport: this.selectedDelegate.passport,
          signature: data
        });
        await this.loadDelegatesData(this.filters);
        this.onClearSignature();
        this.onCloseModal();
        this.selectedDelegate = {
          id: 0,
          passport: "",
          phone: "",
          name: ""
        };
      } catch (e) {
        const err = e as any;
        await this.$error(err.errors);
      }
    }
  }
  async onSetGrade() {
    const data: { [k: string]: number } = {};
    this.delegates.forEach((item: Delegate) => {
      if (item.currentEvent.pivot.grade > 0) {
        if (item.id) {
          if (this.gradesList[item.id] !== item.currentEvent.pivot.grade) {
            data[item.id] = item.currentEvent.pivot.grade;
          }
        }
      }
    });
    try {
      await Events.setGrade(this.eventId, { delegates: data });
      await this.$success(
        "<strong>Success!</strong>Grades have been successfully submitted!"
      );
      await this.loadDelegatesData(this.filters);
    } catch (e) {
      const err = e as any;
      await this.$error(err.errors);
    }
  }
  onCloseModal() {
    this.$modal.hide("attend-modal");
  }
  onSetAttendStatus(delegate: Delegate) {
    this.selectedDelegate = {
      id: delegate.id,
      passport: delegate.passport,
      phone: delegate.phone,
      name: `${delegate.name} ${delegate.surname}`
    };
    this.$modal.show("attend-modal");
  }
  async loadDelegatesData(
    filters: { company: number | string | null; name: string } = {
      company: null,
      name: ""
    }
  ) {
    try {
      const delegatesWithPagination = await Events.allDelagates(
        this.eventId,
        filters
      );
      const delegates = delegatesWithPagination.data || [];

      this.delegates = delegates?.map((delegate: Delegate) => {
        delegate.currentEvent = delegate.events[0];
        if (delegate.currentEvent.pivot.grade === 0) {
          delegate.currentEvent.pivot.grade = null;
        }
        if (delegate.currentEvent.pivot.grade === -1) {
          delegate.currentEvent.pivot.grade = null;
        }
        if (delegate.id) {
          this.gradesList[delegate.id] = delegate.currentEvent.pivot.grade;
        }
        delegate.isDisabled =
          delegate.currentEvent.pivot.attend === 0 ||
          (!!delegate.currentEvent.pivot.grade &&
            delegate.currentEvent.pivot.attend === 1);
        return delegate;
      });
      this.delegatesWithPagination = delegatesWithPagination;
    } catch (e) {
      const err = e as any;
      await this.$error(err.errors);
    }
  }
  validateMinMax(currentVal: number, min: number, max: number) {
    if (currentVal < min || currentVal > max) {
      return undefined;
    }

    return currentVal;
  }
  openTrainingRegisterDownload(delegate: any) {
    this.selectedDelegate = delegate;
    this.$modal.show("training-course-register-download-modal");
  }
  closeTrainingRegisterDownload() {
    this.selectedDelegate = { ...this.initialSelectedDelegate };
    this.$modal.hide("training-course-register-download-modal");
  }
}
