
import { Companies, Events } from "@/api";
import { Delegate, DelegatesWithPagination } from "@/api/types";
import TitleButtons from "@/components/common/TitleButtons.vue";
import moment from "moment";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import PageTitle from "../common/PageTitle.vue";
import TrainingRegisterDownloadModal from "./TrainingRegisterDownloadModal.vue";
import ViewFilesModal from "./ViewFilesModal.vue";

const Chats = namespace("chats");
const Delegates = namespace("delegate");

@Component({
  components: {
    TitleButtons,
    PageTitle,
    ViewFilesModal,
    TrainingRegisterDownloadModal
  }
})
export default class DelegateOnlineCoursesGradeList extends Vue {
  @Chats.Mutation("setCurrentlyOpenChatId")
  setCurrentlyOpenChatId!: (id: string) => void;
  @Chats.Action("getMessagesByCurrentChatId")
  getMessagesByCurrentChatId!: (id: string) => void;
  @Delegates.Mutation("setDelegatesForEvent")
  setDelegatesForEvent!: (delegates: any[]) => void;
  @Chats.State("activeTab")
  activeTab!: number;
  @Chats.Mutation("setActiveTab")
  setActiveTab!: (page: number) => void;
  @Chats.Mutation("setPage")
  setPage!: (page: number) => void;
  @Prop({ required: true })
  eventId!: string;
  @Prop({ required: true })
  instructorId!: string;
  @Prop({ required: true })
  eventDelegates!: any[];
  @Prop({ required: true })
  offlineTrainings!: any[];
  @Prop()
  nextEisaDateRequired!: boolean;

  get canDownloadTrainingRegister() {
    return !!this.filters.company;
  }

  get companiesInEvent() {
    return this.eventDelegates.map(el => el.companyName);
  }

  get filteredCompaniesListByCompanyInEvent() {
    return this.companies.filter(el => this.companiesInEvent.includes(el.name));
  }

  headersTitle = [
    { icon: "mdi-calendar-month", header: "Attendance and Graduation" }
  ];
  delegates: Array<Delegate> = [];
  delegateName = "";
  companies: { id: string; name: string }[] = [];
  filters = {
    company: null,
    name: ""
  };
  delegatesWithPagination: DelegatesWithPagination = {
    meta: {
      perPage: 10
    }
  };
  options = {};
  page = 1;
  menu: { [k: string]: boolean } = {};

  selectedDelegate: any = null;
  selectedDelegateId = "";
  isOpenTrainingRegisterDownload = false;

  get headers() {
    const headers: {
      text: string;
      value: string;
      sortable?: boolean;
      width?: string;
      align?: string;
    }[] = [
      {
        text: "Name",
        value: "name"
      },
      {
        text: "Company",
        value: "company.name"
      },
      {
        text: "Passport / ID",
        value: "passport"
      },
      {
        text: "Email",
        value: "email"
      },
      {
        text: "Cell Phone",
        value: "cellPhone"
      },
      {
        text: "Progress",
        value: "progressPercentage"
      },
      { text: "Grade", value: "gradeStatus", align: "center" }
    ];

    // eslint-disable-next-line no-constant-condition
    if (this.nextEisaDateRequired) {
      headers.push({
        text: "Next EISA",
        value: "nextEisaDate",
        sortable: false,
        width: "200px",
        align: "center"
      });
    }

    headers.push({
      text: "Actions",
      value: "controls",
      sortable: false,
      width: "250px",
      align: "center"
    });
    return headers;
  }
  get showTrainingRegister() {
    return this.offlineTrainings?.length;
  }

  async created() {
    this.companies = await Companies.listWithoutPagination();
    await this.loadDelegatesData(this.filters);
    this.setDelegatesForEvent(this.delegates);
    // if (this.nextEisaDate) {
    //   this.eisaDate = this.nextEisaDate;
    // }
  }

  @Watch("filters", { deep: true })
  async onChangeFilters() {
    await this.loadDelegatesData(this.filters);
  }

  formattedNextEISADate(date: Date) {
    return date ? moment(date, "YYYY-MM-DD").format("DD/MM/YYYY") : "";
  }

  async loadDelegatesData(
    filters: { company: number | string | null; name: string } = {
      company: null,
      name: ""
    }
  ) {
    try {
      const delegatesWithPagination = await Events.allDelagates(
        this.eventId,
        filters
      );
      const delegates = delegatesWithPagination.data || [];
      this.delegates = delegates?.map((delegate: Delegate) => {
        delegate.currentEvent = delegate.events[0];
        if (delegate.currentEvent.pivot.grade === 0) {
          delegate.currentEvent.pivot.grade = null;
        }
        if (delegate.currentEvent.pivot.grade === -1) {
          delegate.currentEvent.pivot.grade = null;
        }
        delegate.isDisabled =
          delegate.currentEvent.pivot.attend === 0 ||
          (!!delegate.currentEvent.pivot.grade &&
            delegate.currentEvent.pivot.attend === 1);

        const event = this.eventDelegates.find(
          (event: any) => event.delegateId == delegate.id
        );
        delegate.nextEisaDate = event?.nextEisaDate || "";
        delegate.progressPercentage = event?.progressPercentage || 0;
        return delegate;
      });
      this.delegatesWithPagination = delegatesWithPagination;
    } catch (e) {
      const err = e as any;
      await this.$error(err.errors);
    }
  }

  approved() {
    this.loadDelegatesData();
  }

  validateMinMax(currentVal: number, min: number, max: number) {
    if (currentVal < min || currentVal > max) {
      return undefined;
    }

    return currentVal;
  }
  onView(id: string) {
    this.$modal.show("files-modal");
    this.selectedDelegateId = id;
  }
  onChat(delegate: any) {
    const id = `event.${this.eventId}.${this.instructorId}.${delegate.id}`;

    this.setActiveTab(1);
    this.setPage(1);
    this.setCurrentlyOpenChatId(id);
    this.getMessagesByCurrentChatId(id);
    this.$router.push("/chat");
  }
  async eisaDateInput(date: Date, delegateId: string) {
    this.menu[delegateId] = false;

    try {
      await Events.setNextEisaDateToDelegate(this.eventId, delegateId, date);
      this.$success("Next EISA date has been saved");
    } catch (error) {
      const err = error as any;
      this.$error(
        err.message ? { err: err.message } : { err: "Something went wrong" }
      );
    }
  }
  openTrainingRegisterDownload(delegate: any) {
    this.selectedDelegate = delegate;
    this.$modal.show("training-register-download-modal");
  }
  closeTrainingRegisterDownload() {
    this.$modal.hide("training-register-download-modal");
    this.selectedDelegate = null;
  }
  async onDownloadTrainingRegister() {
    try {
      if (this.filters.company) {
        const fileName = `${this.eventId}.pdf`;
        const data = await Events.downloadTrainingRegister(
          this.eventId,
          this.filters.company
        );
        const downloadUrl = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");

        link.href = downloadUrl;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
    } catch (e) {
      const err = e as any;
      await this.$error(err.errors);
    }
  }
}
