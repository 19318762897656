
import { Events } from "@/api";
import { Component, Prop, Vue } from "vue-property-decorator";
import TitleButtons from "../common/TitleButtons.vue";

@Component({ components: { TitleButtons } })
export default class TrainingRegisterDownloadModal extends Vue {
  @Prop({ required: true, default: () => [] })
  offlineTrainings!: any[];
  @Prop({ required: true })
  delegate!: any;
  @Prop({ required: true })
  modalRef!: any;

  selectedDelegate: any = null;
  // onCloseSingModal=false
  buttonsArray = [
    {
      text: "Sign",
      icon: "mdi-checkbox-marked-circle-outline",
      color: "dark",
      action: "save"
    }
  ];

  get delegateFullName() {
    return this.delegate
      ? `${this.delegate.name} ${this.delegate.surname}`
      : "";
  }

  get offlineTrainingsForDelegate() {
    const training = (this.offlineTrainings || []).map(session => {
      const delegate: any = session.delegates.find(
        (delegate: any) => delegate.delegateId == this.delegate?.id
      );

      return {
        id: session.id,
        title: session.title,
        address: session.address,
        startedAt: session.startedAt,
        finishedAt: session.finishedAt,
        delegate: delegate
      };
    });

    return training;
  }

  onSetAttendStatus(sessionId: number) {
    this.selectedDelegate = {
      id: this.delegate.id,
      passport: this.delegate.passport,
      phone: this.delegate.phone,
      name: `${this.delegate.name} ${this.delegate.surname}`,
      sessionId
    };
    this.$nextTick(() => {
      this.$modal.show("attend-modal");
    });
  }
  close() {
    this.$emit("close");
  }
  onCloseSingModal() {
    this.selectedDelegate = null;
    this.$modal.hide("attend-modal");
  }
  onClearSignature() {
    (this.$refs.signaturePad as Vue & {
      clearSignature: () => void;
    }).clearSignature();
  }
  onSigned(signature: string) {
    const session = this.offlineTrainings.find(
      session => session.id == this.selectedDelegate.sessionId
    );
    if (session) {
      const delegate: any = session.delegates.find(
        (delegate: any) => delegate.delegateId == this.delegate?.id
      );
      if (delegate) {
        delegate.signature = signature;
      }
    }
  }

  async onSaveSignature() {
    const errors = [];

    const { isEmpty, data: signature } = (this.$refs.signaturePad as Vue & {
      saveSignature: () => any;
    }).saveSignature();

    if (isEmpty) {
      errors.push("The signature should not be empty!");
    }

    if (errors.length > 0) {
      const message = errors.reduce((res: string, error: string) => {
        return res + `${error}<br>`;
      }, "");
      await this.$dialog.error({
        text: message,
        title: "Error",
        persistent: false,
        actions: {
          Yes: {
            color: "red",
            text: "OK"
          }
        }
      });
    } else {
      const data = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        delegate_id: this.delegate.id,
        signature
      };
      try {
        await Events.signEventOfflineTraining(
          this.selectedDelegate.sessionId,
          data
        );
        this.onSigned(signature);
        this.onCloseSingModal();
      } catch (e) {
        const err = e as any;
        const error = err?.result?.message || "";

        await this.$error(error ? { err: error } : err.errors);
      }
    }
  }
  async downloadTrainingRegister(id: any) {
    try {
      const data = await Events.downloadOfflineTrainingRegister(
        id,
        this.delegate.id
      );
      const fileName = `${this.delegate.id}.pdf`;

      const downloadUrl = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");

      link.href = downloadUrl;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();

      this.$success("Training Register Downloaded Successfully");
    } catch (e) {
      const err = e as any;
      const error = err?.result?.message || "";

      await this.$error(error ? { err: error } : err.errors);
    }
  }
}
